var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"investor-form"},[_c('Header',{}),_c('h2',{staticClass:"margin-top25"}),_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('form-wizard',{staticClass:"mb-3 pt-1",attrs:{"color":"#366ce3","title":_vm.$t('InvestorForm.Register_investor'),"subtitle":_vm.$t('InvestorForm.Complete_your_account'),"shape":"square","finish-button-text":_vm.$t('common.Submit'),"next-button-text":_vm.$t('common.Next'),"back-button-text":_vm.$t('common.Previous')},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"title":_vm.$t('InvestorForm.Investor_Details'),"icon":"feather icon-file-text","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("InvestorForm.Investor_Details"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("InvestorForm.Enter_Investor_Details"))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Full_Name'),"label-for":"Fomraml_name"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Fomraml_name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Full_Name')},model:{value:(_vm.createInvestorProfile.name),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "name", $$v)},expression:"createInvestorProfile.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"john.doe@email.com"},model:{value:(_vm.createInvestorProfile.emailValue),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "emailValue", $$v)},expression:"createInvestorProfile.emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Short_name'),"label-for":"slugan"}},[_c('validation-provider',{attrs:{"name":"Short Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"slugan","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Short_name')},model:{value:(_vm.createInvestorProfile.slugan),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "slugan", $$v)},expression:"createInvestorProfile.slugan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Phone_Number'),"label-for":"Phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"}},[_c('VuePhoneNumberInput',{attrs:{"color":"#ea5455","error-color":"#ea5455","translations":_vm.$store.state.locale.locale == 'ar'
                         ? _vm.translationsArabic
                         : _vm.translations},on:{"update":_vm.onUpdate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),(!_vm.isValidNumber)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("common.phone_Invalid")))]):_vm._e()],1)],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('InvestorForm.Investor_Info'),"icon":"feather icon-user","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("InvestorForm.Investor_Info"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("InvestorForm.Enter_Investor_Info")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Your_Designation'),"label-for":"Designation"}},[_c('validation-provider',{attrs:{"name":"Designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Designation","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Your_Designation')},model:{value:(_vm.createInvestorProfile.Designation),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "Designation", $$v)},expression:"createInvestorProfile.Designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Interested ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.interestedIn'),"label-for":"Interested","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Interested","options":_vm.categories,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createInvestorProfile.SelectedInterested),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "SelectedInterested", $$v)},expression:"createInvestorProfile.SelectedInterested"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Investor Role ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Investor_Role'),"label-for":"Investor-Role","state":errors.length > 0 ? false : null}},[_c('b-form-select',{attrs:{"id":"Investor-Role","options":_vm.$store.state.locale.locale == 'ar'
                         ? _vm.createInvestorProfile.InvestorRoleOptionArabic
                         : _vm.createInvestorProfile.InvestorRoleOption,"label":"text"},model:{value:(_vm.createInvestorProfile.SelectedInvestorRole),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "SelectedInvestorRole", $$v)},expression:"createInvestorProfile.SelectedInvestorRole"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Industry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.industries_investment_belong'),"label-for":"Industry","state":errors.length > 0 ? false : null}},[_c('div',{attrs:{"id":"app"}},[_c('treeselect',{attrs:{"multiple":true,"placeholder":_vm.$t('common.Select'),"options":_vm.industries,"normalizer":_vm.$store.state.locale.locale == 'ar'
                           ? _vm.normalizerArabic
                           : _vm.normalizer},on:{"input":_vm.handeTreeSelect},model:{value:(_vm.selectedIndustry),callback:function ($$v) {_vm.selectedIndustry=$$v},expression:"selectedIndustry"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Investment_Country'),"label-for":"Country","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"Country","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"multiple":"","label":"text"},model:{value:(_vm.createInvestorProfile.selectedCountry),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "selectedCountry", $$v)},expression:"createInvestorProfile.selectedCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Company_Name'),"label-for":"Company-Name"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Company-Name","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Company_Name')},model:{value:(_vm.createInvestorProfile.CompanyName),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "CompanyName", $$v)},expression:"createInvestorProfile.CompanyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Desired Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Desired_Investing_Addresses'),"label-for":"DesiredCountry","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"DesiredCountry","multiple":"","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createInvestorProfile.selectedDesiredCountry),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "selectedDesiredCountry", $$v)},expression:"createInvestorProfile.selectedDesiredCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Currency"}},[_vm._v(_vm._s(_vm.$t("InvestorForm.Currency")))]),_c('v-select',{attrs:{"id":"Currency","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createInvestorProfile.SelectedCurrency),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "SelectedCurrency", $$v)},expression:"createInvestorProfile.SelectedCurrency"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Investment_Min_Size'),"label-for":"Investment-Min-Size"}},[_c('validation-provider',{attrs:{"name":"Investment Min Size","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Investment-Min-Size","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Investment_Min_Size')},model:{value:(_vm.createInvestorProfile.InvestmentMinSize),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "InvestmentMinSize", $$v)},expression:"createInvestorProfile.InvestmentMinSize"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Investment_Max_Size'),"label-for":"Investment-Max-Size"}},[_c('validation-provider',{attrs:{"name":"Investment Max Size","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Investment-Size","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Investment_Max_Size')},model:{value:(_vm.createInvestorProfile.InvestmentMaxSize),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "InvestmentMaxSize", $$v)},expression:"createInvestorProfile.InvestmentMaxSize"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Business_Factors'),"label-for":"Business-Factors"}},[_c('validation-provider',{attrs:{"name":"Business Factors","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Business-Factors","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Business_Factors')},model:{value:(_vm.createInvestorProfile.BusinessFactors),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "BusinessFactors", $$v)},expression:"createInvestorProfile.BusinessFactors"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Brief_overview_investment'),"label-for":"Highlights"}},[_c('validation-provider',{attrs:{"name":"Highlights","rules":("required|max:" + _vm.$maxHighlightsUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Highlights","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.Brief_overview_investment')},model:{value:(_vm.createInvestorProfile.Highlights),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "Highlights", $$v)},expression:"createInvestorProfile.Highlights"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.description'),"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.description')},model:{value:(_vm.createInvestorProfile.description),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "description", $$v)},expression:"createInvestorProfile.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.About_your_company'),"label-for":"Company-Description"}},[_c('validation-provider',{attrs:{"name":"Company Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Company-Description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('InvestorForm.About_your_company')},model:{value:(_vm.createInvestorProfile.CompanyDescription),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "CompanyDescription", $$v)},expression:"createInvestorProfile.CompanyDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('InvestorForm.Social_Links'),"icon":"feather icon-link","before-change":_vm.validationFormSocial}},[_c('validation-observer',{ref:"socialRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("InvestorForm.Social_Links"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("InvestorForm.Enter_Your_Social")))])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Facebook'),"label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.createInvestorProfile.facebookUrl),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "facebookUrl", $$v)},expression:"createInvestorProfile.facebookUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Twitter'),"label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"placeholder":"https://twitter.com/abc"},model:{value:(_vm.createInvestorProfile.twitterUrl),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "twitterUrl", $$v)},expression:"createInvestorProfile.twitterUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.LinkedIn'),"label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false : null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.createInvestorProfile.linkedinUrl),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "linkedinUrl", $$v)},expression:"createInvestorProfile.linkedinUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('InvestorForm.Website'),"label-for":"Other"}},[_c('validation-provider',{attrs:{"name":"Other","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Other","state":errors.length > 0 ? false : null,"placeholder":"https://other.com/abc"},model:{value:(_vm.createInvestorProfile.OtherUrl),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "OtherUrl", $$v)},expression:"createInvestorProfile.OtherUrl"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":_vm.$t('InvestorForm.Attachment'),"icon":"feather icon-file-plus","before-change":_vm.validationFormFiles}},[_c('validation-observer',{ref:"attachmentRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("InvestorForm.Attachment"))+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("InvestorForm.Upload_your_files"))+" ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"profile picture","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"profile-picture"}},[_vm._v(_vm._s(_vm.$t("InvestorForm.Upload_profile_picture")))]),_c('b-form-file',{attrs:{"id":"profile-picture","state":errors.length > 0 ? false : null,"accept":"image/jpeg, image/png, image/gif","placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createInvestorProfile.ProfilePicture),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "ProfilePicture", $$v)},expression:"createInvestorProfile.ProfilePicture"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Company Logo","rules":("required|size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('label',{attrs:{"for":"CompanyLogo"}},[_vm._v(_vm._s(_vm.$t("InvestorForm.Upload_Company_Logo")))]),_c('b-form-file',{attrs:{"id":"CompanyLogo","state":errors.length > 0 ? false : null,"accept":"image/jpeg, image/png, image/gif","placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createInvestorProfile.CompanyLogo),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "CompanyLogo", $$v)},expression:"createInvestorProfile.CompanyLogo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Proof Business","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('label',{attrs:{"for":"ProofBusiness"}},[_vm._v(_vm._s(_vm.$t("InvestorForm.Attach_proof_business")))]),_c('b-form-file',{attrs:{"id":"ProofBusiness","state":errors.length > 0 ? false : null,"accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createInvestorProfile.ProofBusiness),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "ProofBusiness", $$v)},expression:"createInvestorProfile.ProofBusiness"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Proof Business","rules":("size:" + _vm.$sizeFilesUser)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                           var errors = ref.errors;
return [_c('label',{attrs:{"for":"CorporateProfile"}},[_vm._v(_vm._s(_vm.$t("InvestorForm.Attach_Corporate_Profile")))]),_c('b-form-file',{attrs:{"id":"CorporateProfile","state":errors.length > 0 ? false : null,"accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"drop-placeholder":"Drop file here...","browse-text":_vm.$t('common.Browse')},model:{value:(_vm.createInvestorProfile.CorporateProfile),callback:function ($$v) {_vm.$set(_vm.createInvestorProfile, "CorporateProfile", $$v)},expression:"createInvestorProfile.CorporateProfile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[(_vm.errorMessage)?_c('b-alert',{staticClass:"warning p-1",staticStyle:{"color":"red"},attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)],1)],1)],1)],1)],1),_c('Footer',{staticClass:"company-form-footer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }