<template>
  <div class="investor-form">
    <Header class="" />
    <h2 class="margin-top25" />
    <b-overlay :show="show" rounded="sm">
      <form-wizard
        color="#366ce3"
        :title="$t('InvestorForm.Register_investor')"
        :subtitle="$t('InvestorForm.Complete_your_account')"
        shape="square"
        :finish-button-text="$t('common.Submit')"
        :next-button-text="$t('common.Next')"
        :back-button-text="$t('common.Previous')"
        class="mb-3 pt-1"
        @on-complete="formSubmitted"
      >
        <!-- Investor details tab -->
        <!--          :before-change="validationForm"
 -->
        <tab-content
          :title="$t('InvestorForm.Investor_Details')"
          icon="feather icon-file-text"
          :before-change="validationForm"
        >
          <validation-observer ref="accountRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("InvestorForm.Investor_Details") }}
                </h5>
                <small class="text-muted"
                  >{{ $t("InvestorForm.Enter_Investor_Details") }}
                </small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Full_Name')"
                  label-for="Fomraml_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    rules="required"
                  >
                    <b-form-input
                      id="Fomraml_name"
                      v-model="createInvestorProfile.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.Full_Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Email')"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="createInvestorProfile.emailValue"
                      type="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="john.doe@email.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Short_name')"
                  label-for="slugan"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Short Name"
                    rules="required"
                  >
                    <b-form-input
                      id="slugan"
                      v-model="createInvestorProfile.slugan"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.Short_name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Phone_Number')"
                  label-for="Phone"
                >
                  <validation-provider name="phone" rules="required">
                    <VuePhoneNumberInput
                      v-model="phoneNumber"
                      color="#ea5455"
                      error-color="#ea5455"
                      @update="onUpdate"
                      :translations="
                        $store.state.locale.locale == 'ar'
                          ? translationsArabic
                          : translations
                      "
                    />

                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    <small v-if="!isValidNumber" class="text-danger">{{
                      $t("common.phone_Invalid")
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Investor Information tab  :before-change="validationFormInfo" -->
        <tab-content
          :title="$t('InvestorForm.Investor_Info')"
          icon="feather icon-user"
          :before-change="validationFormInfo"
        >
          <validation-observer ref="infoRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("InvestorForm.Investor_Info") }}
                </h5>
                <small class="text-muted">{{
                  $t("InvestorForm.Enter_Investor_Info")
                }}</small>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Your_Designation')"
                  label-for="Designation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Designation"
                    rules="required"
                  >
                    <b-form-input
                      id="Designation"
                      v-model="createInvestorProfile.Designation"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.Your_Designation')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Interested "
                  rules="required"
                >
                  <b-form-group
                    :label="$t('InvestorForm.interestedIn')"
                    label-for="Interested"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Interested"
                      v-model="createInvestorProfile.SelectedInterested"
                      :options="categories"
                      :selectable="
                        (option) => !option.value.includes('select_value')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Investor Role "
                  rules="required"
                >
                  <b-form-group
                    :label="$t('InvestorForm.Investor_Role')"
                    label-for="Investor-Role"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-select
                      id="Investor-Role"
                      v-model="createInvestorProfile.SelectedInvestorRole"
                      :options="
                        $store.state.locale.locale == 'ar'
                          ? createInvestorProfile.InvestorRoleOptionArabic
                          : createInvestorProfile.InvestorRoleOption
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Industry"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('InvestorForm.industries_investment_belong')"
                    label-for="Industry"
                    :state="errors.length > 0 ? false : null"
                  >
                    <div id="app">
                      <treeselect
                        v-model="selectedIndustry"
                        :multiple="true"
                        :placeholder="$t('common.Select')"
                        :options="industries"
                        @input="handeTreeSelect"
                        :normalizer="
                          $store.state.locale.locale == 'ar'
                            ? normalizerArabic
                            : normalizer
                        "
                      />
                    </div>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('InvestorForm.Investment_Country')"
                    label-for="Country"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="Country"
                      v-model="createInvestorProfile.selectedCountry"
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      multiple
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Company_Name')"
                  label-for="Company-Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    rules="required"
                  >
                    <b-form-input
                      id="Company-Name"
                      v-model="createInvestorProfile.CompanyName"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.Company_Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Desired Country"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('InvestorForm.Desired_Investing_Addresses')"
                    label-for="DesiredCountry"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="DesiredCountry"
                      v-model="createInvestorProfile.selectedDesiredCountry"
                      multiple
                      :options="countries"
                      :selectable="
                        (option) => !option.value.includes('nothing_selected')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="2">
                <validation-provider
                  #default="{ errors }"
                  name="Currency"
                  rules="required"
                >
                  <b-form-group :state="errors.length > 0 ? false : null">
                    <label class="required" for="Currency">{{
                      $t("InvestorForm.Currency")
                    }}</label>

                    <v-select
                      id="Currency"
                      v-model="createInvestorProfile.SelectedCurrency"
                      :options="currencies"
                      :selectable="
                        (option) => !option.value.includes('select_value')
                      "
                      label="text"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="2">
                <b-form-group
                  :label="$t('InvestorForm.Investment_Min_Size')"
                  label-for="Investment-Min-Size"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Investment Min Size"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="Investment-Min-Size"
                      v-model="createInvestorProfile.InvestmentMinSize"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.Investment_Min_Size')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  :label="$t('InvestorForm.Investment_Max_Size')"
                  label-for="Investment-Max-Size"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Investment Max Size"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="Investment-Size"
                      v-model="createInvestorProfile.InvestmentMaxSize"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.Investment_Max_Size')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Business_Factors')"
                  label-for="Business-Factors"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Business Factors"
                    rules="required"
                  >
                    <b-form-textarea
                      id="Business-Factors"
                      v-model="createInvestorProfile.BusinessFactors"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.Business_Factors')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Brief_overview_investment')"
                  label-for="Highlights"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Highlights"
                    :rules="`required|max:${$maxHighlightsUser}`"
                  >
                    <b-form-textarea
                      id="Highlights"
                      v-model="createInvestorProfile.Highlights"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="
                        $t('InvestorForm.Brief_overview_investment')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.description')"
                  label-for="description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="createInvestorProfile.description"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.description')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.About_your_company')"
                  label-for="Company-Description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Description"
                    rules="required"
                  >
                    <b-form-textarea
                      id="Company-Description"
                      v-model="createInvestorProfile.CompanyDescription"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('InvestorForm.About_your_company')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- address  tab-->
        <!-- <tab-content
        title="Investor Address"
        icon="feather icon-map-pin"
        :before-change="validationFormAddress"
      >
        <validation-observer ref="addressRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Address</h5>
              <small class="text-muted">Enter Your Address.</small>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content> -->
        <!-- social link -->
        <tab-content
          :title="$t('InvestorForm.Social_Links')"
          icon="feather icon-link"
          :before-change="validationFormSocial"
        >
          <validation-observer ref="socialRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("InvestorForm.Social_Links") }}
                </h5>
                <small class="text-muted">{{
                  $t("InvestorForm.Enter_Your_Social")
                }}</small>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Facebook')"
                  label-for="facebook"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Facebook"
                    rules="url"
                  >
                    <b-form-input
                      id="facebook"
                      v-model="createInvestorProfile.facebookUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://facebook.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Twitter')"
                  label-for="twitter"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Twitter"
                    rules="url"
                  >
                    <b-form-input
                      id="twitter"
                      v-model="createInvestorProfile.twitterUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://twitter.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.LinkedIn')"
                  label-for="linked-in"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="LinkedIn"
                    rules="url"
                  >
                    <b-form-input
                      id="linked-in"
                      v-model="createInvestorProfile.linkedinUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://linkedin.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('InvestorForm.Website')"
                  label-for="Other"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Other"
                    rules="url"
                  >
                    <b-form-input
                      id="Other"
                      v-model="createInvestorProfile.OtherUrl"
                      :state="errors.length > 0 ? false : null"
                      placeholder="https://other.com/abc"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <!-- files -->
        <tab-content
          :title="$t('InvestorForm.Attachment')"
          icon="feather icon-file-plus"
          :before-change="validationFormFiles"
        >
          <validation-observer ref="attachmentRules" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  {{ $t("InvestorForm.Attachment") }}
                </h5>
                <small class="text-muted"
                  >{{ $t("InvestorForm.Upload_your_files") }}
                </small>
              </b-col>
              <!-- <b-col md="8">
              <b-form-group
                label="Add profile picture:"
                label-for="profile-picture"
              >
                <vue-dropzone
                  id="profile-picture"
                  ref="myVueDropzone"
                  v-model="createInvestorProfile.ProfilePicture"
                  :options="dropzoneOptionsProfilePicture"
                  @click="removeThisFile(UploadFile)"
                />
              </b-form-group>
            </b-col> -->
              <!-- <b-col md="8">
              <b-form-group
                label="Add your Company Logo:"
                label-for="Company-Logo"
              >
                <vue-dropzone
                  id="Company-Logo"
                  ref="myVueDropzone"
                  v-model="createInvestorProfile.CompanyLogo"
                  :options="dropzoneOptionsCompanyLogo"
                  @click="removeThisFile(UploadFile)"
                />
              </b-form-group>
            </b-col> -->
              <!-- <b-col md="8">
              <b-form-group
                label="Attach proof of business for faster verification:"
                label-for="Proof-Business"
              >
                <vue-dropzone
                  id="Proof-Business"
                  ref="myVueDropzone"
                  v-model="createInvestorProfile.ProofBusiness"
                  :options="dropzoneOptionsProofBusiness"
                  @click="removeThisFile(UploadFile)"
                />
              </b-form-group>
            </b-col> -->
              <!-- <b-col md="8">
              <b-form-group
                label="Attach Corporate Profile and Terms of Engagement if any:"
                label-for="Corporate-Profile"
              >
                <vue-dropzone
                  id="Corporate-Profile"
                  ref="myVueDropzone"
                  v-model="createInvestorProfile.CorporateProfile"
                  :options="dropzoneOptionsCorporateProfile"
                  @click="removeThisFile(UploadFile)"
                />
              </b-form-group>
            </b-col> -->

              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="profile picture"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label class="required" for="profile-picture">{{
                      $t("InvestorForm.Upload_profile_picture")
                    }}</label>
                    <b-form-file
                      id="profile-picture"
                      v-model="createInvestorProfile.ProfilePicture"
                      :state="errors.length > 0 ? false : null"
                      accept="image/jpeg, image/png, image/gif"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Logo"
                    :rules="`required|size:${$sizeFilesUser}`"
                  >
                    <label class="" for="CompanyLogo">{{
                      $t("InvestorForm.Upload_Company_Logo")
                    }}</label>
                    <b-form-file
                      id="CompanyLogo"
                      v-model="createInvestorProfile.CompanyLogo"
                      :state="errors.length > 0 ? false : null"
                      accept="image/jpeg, image/png, image/gif"
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Proof Business"
                    :rules="`size:${$sizeFilesUser}`"
                  >
                    <label class="" for="ProofBusiness">{{
                      $t("InvestorForm.Attach_proof_business")
                    }}</label>
                    <b-form-file
                      id="ProofBusiness"
                      v-model="createInvestorProfile.ProofBusiness"
                      :state="errors.length > 0 ? false : null"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      multiple
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Proof Business"
                    :rules="`size:${$sizeFilesUser}`"
                  >
                    <label class="" for="CorporateProfile">{{
                      $t("InvestorForm.Attach_Corporate_Profile")
                    }}</label>
                    <b-form-file
                      id="CorporateProfile"
                      v-model="createInvestorProfile.CorporateProfile"
                      :state="errors.length > 0 ? false : null"
                      accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      multiple
                      :placeholder="$t('common.Choose_file_drop')"
                      drop-placeholder="Drop file here..."
                      :browse-text="$t('common.Browse')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="warning"
                  class="warning p-1"
                  style="color: red"
                  >{{ errorMessage }}</b-alert
                >
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
    <Footer class="company-form-footer" />
  </div>
</template>

<script>
import store from "@/store";
import Header from "@/layouts/landpage/Header.vue";
import Footer from "@/layouts/landpage/Footer.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {
  BRow,
  BCol,
  BAlert,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { codeIcon } from "./code";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapActions, mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

import "../../../layouts/landpage/ValidationFrom.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BAlert,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    vueDropzone: vue2Dropzone,
    VuePhoneNumberInput,
    BFormSelect,
    Treeselect,
    Header,
    Footer,
    BFormFile,
    BOverlay,
  },

  data() {
    return {
      translations: {
        countrySelectorLabel: "Country code",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone number",
        example: "Exemple :",
      },
      translationsArabic: {
        countrySelectorLabel: "رمز البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الهاتف",
        example: "مثال :",
      },
      show: false,
      errorMessage: "",
      isValidNumber: null,
      sendPhoneNumber: null,
      phoneNumber: null,
      phoneCode: "",
      selectedIndustry: [],
      createInvestorProfile: {
        name: "",
        emailValue: "",
        slugan: "",
        SelectedphoneCode: "",
        Phone: "",
        BusinessFactors: "",
        Designation: "",
        SelectedInterested: "",
        SelectedInvestmentCurrency: "",
        InvestmentPrice: "",
        SelectedLoanCurrency: "",
        loanAmount: "",
        SelectedSellingCurrency: "",
        selling: "",
        SelectedInvestorRole: "",
        Highlights: "",
        description: "",
        CompanyName: "",
        CompanyDescription: "",
        selectedDesiredCountry: [],
        selectedSaleType: "",
        SelectedCurrency: "",
        InvestmentMinSize: "",
        InvestmentMaxSize: "",
        selectedCity: "",
        selectedState: "",
        selectedCountry: [],
        pincode: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        ProfilePicture: null,
        CompanyLogo: null,
        ProofBusiness: [],
        CorporateProfile: [],
        InvestorRoleOption: [
          { value: "1", text: "Individual Investor / Buyer" },
          { value: "2", text: "Corporate Investor / Buyer" },
          {
            label: "Lender",
            options: [
              { value: "3", text: "Bank" },
              { value: "4", text: "Financial Institution" },
            ],
          },
          {
            label: "Financial Advisor",
            options: [
              { value: "5", text: "Accounting Firm" },
              { value: "6", text: "Business Broker" },
              { value: "7", text: "Financial Consultant" },
              { value: "8", text: "Investment Bank" },
              { value: "9", text: "Law Firm" },
              { value: "10", text: "M&A Advisor" },
              { value: "11", text: "Merchant Bank" },
              { value: "12", text: "Commercial Real Estate Broker" },
            ],
          },
          {
            label: "Fund",
            options: [
              { value: "13", text: "Venture Capital Firm" },
              { value: "14", text: "Private Equity Firm" },
              { value: "15", text: "Family Office" },
              { value: "16", text: "Hedge Fund" },
              { value: "17", text: "Search Fund" },
            ],
          },
        ],
        InvestorRoleOptionArabic: [
          { value: "1", text: "مستثمر/مشتري فردي" },
          { value: "2", text: "مستثمر/مشتري شركة" },
          {
            label: "المُقرض",
            options: [
              { value: "3", text: "مصرف" },
              { value: "4", text: "مؤسسة مالية" },
            ],
          },
          {
            label: "مستشار مالي",
            options: [
              { value: "5", text: "شركة محاسبة" },
              { value: "6", text: "وسيط أعمال" },
              { value: "7", text: "مستشار مالي" },
              { value: "8", text: "بنك استثمار" },
              { value: "9", text: "شركة محاماة" },
              { value: "10", text: "مستشار الاندماج والاستحواذ" },
              { value: "11", text: "بنك تجاري" },
              { value: "12", text: "وسيط عقاري تجاري" },
            ],
          },
          {
            label: "تمويل",
            options: [
              { value: "13", text: "شركة رأس المال الاستثماري" },
              { value: "14", text: "شركة الاسهم الخاصة" },
              { value: "15", text: "مكتب العائلة" },
              { value: "16", text: "صندوق التحوط" },
              { value: "17", text: "صندوق البحث" },
            ],
          },
        ],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Open Auction" },
          { value: "3", text: "Timed Auction" },
        ],
      },
      dropzoneOptionsProfilePicture: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsCompanyLogo: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: "image/*",
        maxFilesize: 3,
        maxFiles: 1,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsProofBusiness: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
      dropzoneOptionsCorporateProfile: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        acceptedFiles: "image/*,.xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf",
        maxFilesize: 3,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  computed: {
    ...mapGetters({
      categories: "profile/getInvestorCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      fetchCurrencies: "currency/retreiveCurrencies",
      createInvestor: "profile/createInvestor",
      fetchCountries: "country/retreiveCountries",
    }),
    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.selectedIndustry.length = 6;
      }
    },
    onUpdate(payload) {
      // console.log(payload);
      if (this.phoneNumber == null) {
        this.isValidNumber = true;
      } else {
        this.isValidNumber = payload.isValid;
      }
      this.PhoneCode = payload.countryCode;
      this.sendPhoneNumber = payload.phoneNumber;
    },

    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    formSubmitted() {
      this.show = true;
      // const formatIndustries = [];
      // this.selectedIndustry.forEach((element) => {
      //   formatIndustries.push({
      //     _id: element,
      //   });
      // });
      const investorData = new FormData();
      // eslint-disable-next-line no-underscore-dangle
      investorData.append("userId", this.user._id);
      investorData.append("userType", "investor");
      // investorData.append("role", "investor");
      investorData.append("legalName", this.createInvestorProfile.name);
      investorData.append(
        "investorEmail",
        this.createInvestorProfile.emailValue
      );
      investorData.append("shortName", this.createInvestorProfile.slugan);
      // investorData.append(
      //   "phoneNumber",
      //   this.sendPhoneNumber.replace(/\s/g, "")
      // );
      // investorData.append("phoneCode", this.phoneCode);
      if (this.sendPhoneNumber) {
        investorData.append(
          "phoneNumber",
          this.sendPhoneNumber.replace(/\s/g, "")
        );
      }
      investorData.append("phoneCode", this.PhoneCode);

      investorData.append(
        "designation",
        this.createInvestorProfile.Designation
      );
      investorData.append(
        "investorCategory",
        this.createInvestorProfile.SelectedInterested.value
      );
      investorData.append(
        "investorRole",
        this.createInvestorProfile.SelectedInvestorRole
      );

      // const industriesArray = [];
      // this.selectedIndustry.forEach((element) => {
      //   industriesArray.push({
      //     id: element,
      //   });
      // });
      // if (this.selectedIndustry) {
      //   investorData.append("industries", JSON.stringify(industriesArray));
      // }

      investorData.append("industries", JSON.stringify(this.selectedIndustry));
      investorData.append(
        "businessFactors",
        this.createInvestorProfile.BusinessFactors
      );
      investorData.append("highlights", this.createInvestorProfile.Highlights);
      investorData.append(
        "description",
        this.createInvestorProfile.description
      );
      investorData.append(
        "companyName",
        this.createInvestorProfile.CompanyName
      );
      investorData.append(
        "companyDescription",
        this.createInvestorProfile.CompanyDescription
      );
      investorData.append(
        "interestedCountry",
        JSON.stringify(
          this.pluck(this.createInvestorProfile.selectedDesiredCountry, "value")
        )
      );
      investorData.append(
        "investorCurrency",
        this.createInvestorProfile.SelectedCurrency.value
      );
      investorData.append(
        "minimumInvestment",
        this.createInvestorProfile.InvestmentMinSize
      );
      investorData.append(
        "maximumInvestment",
        this.createInvestorProfile.InvestmentMaxSize
      );
      investorData.append(
        "address",
        JSON.stringify(
          this.pluck(this.createInvestorProfile.selectedCountry, "value")
        )
      );
      if (this.createInvestorProfile.facebookUrl) {
        investorData.append(
          "investorFacebook",
          this.createInvestorProfile.facebookUrl
        );
      }
      if (this.createInvestorProfile.twitterUrl) {
        investorData.append(
          "investorTwitter",
          this.createInvestorProfile.twitterUrl
        );
      }
      if (this.createInvestorProfile.linkedinUrl) {
        investorData.append(
          "investorLinkedIn",
          this.createInvestorProfile.linkedinUrl
        );
      }
      if (this.createInvestorProfile.OtherUrl) {
        investorData.append(
          "investorOtherLinks",
          this.createInvestorProfile.OtherUrl
        );
      }

      investorData.append(
        "profilePicture",
        this.createInvestorProfile.ProfilePicture
      );
      investorData.append(
        "companyLogo",
        this.createInvestorProfile.CompanyLogo
      );

      if (this.createInvestorProfile.ProofBusiness) {
        for (const i of Object.keys(this.createInvestorProfile.ProofBusiness)) {
          investorData.append(
            "investorBusinessProof",
            this.createInvestorProfile.ProofBusiness[i]
          );
        }
      }

      if (this.createInvestorProfile.CorporateProfile) {
        for (const i of Object.keys(
          this.createInvestorProfile.CorporateProfile
        )) {
          investorData.append(
            "otherDocuments",
            this.createInvestorProfile.CorporateProfile[i]
          );
        }
      }

      this.createInvestor(investorData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.show = false;
          this.$router.replace("/pages/pricing", () => {
            this.$router.go(0);
          });
          // this.$router.push({ name: "pages-pricing" });
        })
        .catch((error) => {
          this.show = false;
          this.errorMessage = error.response.data.error.message;
        });
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success && this.isValidNumber) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
            window.scrollTo(0, 0);
          } else {
            reject();
          }
        });
      });
    },
    validationFormFiles() {
      return new Promise((resolve, reject) => {
        this.$refs.attachmentRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormFiles() {
      return new Promise((resolve, reject) => {
        this.$refs.attachmentRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
  },
  created() {
    // $themeConfig.layout.menu.hidden = true;
    // console.log(store.state.appConfig.layout.menu.hidden);
    // console.log(store.state.appConfig.layout.footerType);
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "hidden" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
  },
};
</script>

<style type="text/css">
[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}

[dir] .vs__selected {
  background-color: #366ce3;
}
.margin-top25 {
  margin-top: 25px;
}
</style>
<style scoped>
.breadcrumbs-top {
  margin-top: 65px;
}
/* .company-form {
  margin-top: 25px;
} */
/* .company-form-footer {
  margin-top: 100px;
} */
</style>
